@import "node_modules/bootstrap/scss/bootstrap";

html {
  min-height: 100%;
}

body {
  height: 100%;
  background-color: black;
  background-image: url("./Assets/AdobeStock_541096083.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

body, h1 {
  color: white;
}

#root {
  height: 100%;
}

#homePageTopGraph {
  width: -webkit-fill-available;
  max-width: 800px;
}

.mt-10, {
  margin-top: 6rem !important;
}

.mb-10, {
  margin-bottom: 6rem !important;
}

.mt-8, {
  margin-top: 4.8rem !important;
}

.mb-8, {
  margin-bottom: 4.8rem !important;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .mt-lg-10 {
    margin-top: 6rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 6rem !important;
  }

  .mt-lg-8 {
    margin-top: 4.8rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 4.8rem !important;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .mt-xl-10 {
    margin-top: 6rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 6rem !important;
  }

  .mt-xl-8 {
    margin-top: 4.8rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 4.8rem !important;
  }
}

.card {
  background-color: #222638;
  color: white;

  .card-img-top {
    padding: 10px 10px 0px 10px;
    max-height: 350px;
    margin-bottom: 20px;
  }

  .card-body {
    padding: 0 10px 10px 10px;
  }

  .card-title {
    font-weight: 600;
    margin-bottom: 16px;
  }

  //&.cardHover {
  //  &:hover {
  //    cursor: pointer;
  //    border: 1px solid white;
  //  }
  //}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .card {
    display: flex;
    flex-direction: row;

    &.even {
      flex-direction: row-reverse;
    }

    .card-img-top {
      padding: 20px;
      margin-bottom: 0;
      max-width: 50%;
    }

    .card-body {
      padding: 20px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .card {
    flex-direction: column;

    &.even {
      flex-direction: column;
    }

    .card-img-top {
      padding: 20px;
      margin-bottom: 7px;
      max-width: unset;
    }

    .card-body {
      padding: 0 20px 20px 20px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .card {
    .card-body {
      padding-bottom: 20px;
    }
  }
}

//forms
.text-muted {
  color: lightgrey !important;
}

#mainContainer {
  padding-top: 75px;
  transition: padding-top 1.5s;
  max-width: unset;

  &.mainHeaderOpen {
    padding-top: 200px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    max-width: 960px;
    padding-top: 56px;
  }
}

.jumbo {
  border-radius: 20px;
  padding: 80px;
}

.alert-danger {
  color: white;
  background-color: rgba(172, 34, 48, 0.4);
  border-color: rgb(172, 34, 48);
}

.alert-success {
  color: white;
  background-color: rgba(53, 140, 140, 0.4);
  border-color: rgb(53, 140, 140);
}

.text-justify {
  text-align: justify;
}

@import "./MainHeader/mainHeader.scss";
@import "./Footer/footer.scss";
@import "./Contact/contact.scss";
@import "./LoadingSpinner/loadingSpinner.scss";
