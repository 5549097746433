#footer {
  .container {
    max-width: unset;
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      max-width: 960px;
    }
  }

  #copyright {
    border-top: 0.25px solid;
  }
}